<template>
  <!-- 督办记录 -->
  <div class="setting_main_bg">
    <div class="over_cont">
      <div class="over_box">
        <!-- <div class="btn_group">
          <div class="iconfont icondaochu settingExport" @click="handleExport"><span>导出</span></div>
        </div> -->
        <div class="flexAC ut_top" >
          <el-input v-model="intaskname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <el-select v-model="intaskstate" placeholder="是否已巡检" class="utF setSe" style="margin-right:10px;">
            <el-option
              v-for="(item,index) in taskstateOption"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="over_table">
          <el-table
            stripe
            :data="overList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="单位名称"
              prop="companyname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="巡检计划名称"
              prop="inplanname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="督办人"
              prop="overuser">
            </el-table-column>
            <el-table-column
              label="接收人"
              prop="username">
            </el-table-column>
            <el-table-column
              label="巡检计划完成时间"
              prop="intasketime">
            </el-table-column>
            <el-table-column
              label="督办时间"
              prop="intasktime">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 详情 -->
  <el-dialog title="督办记录详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
    <div class="flexSC">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检计划基本信息</p>
        </div>
        <div class="flexCB">
          <p class="udiIP">任务编号：{{ detailInfo.intaskcode }}</p>
          <p class="udiIP">任务名称：{{ detailInfo.inplanname }}</p>
        </div>
        <!-- <div class="flexCB">
          <p class="udi_s">开始时间：{{ inplanList.intaskstime }}</p>
          <p class="udi_s">结束时间：{{ inplanList.intasketime }}</p>
        </div>
        <div class="flexCB">
          <p class="udi_s">状态：
            <span v-if="inplanList.intaskstate == 1">待巡检</span>
            <span v-if="inplanList.intaskstate == 2">巡检中</span>
            <span v-if="inplanList.intaskstate == 3">已巡检</span>
          </p>
          <p class="udi_s">是否逾期：
            <span v-if="inplanList.intaskwttl == 1">未逾期</span>
            <span v-if="inplanList.intaskwttl == 2">已逾期</span>
          </p>
        </div>
        <div class="flexCB">
          <p class="udi_s">是否停用：
            <span v-if="inplanList.intaskdise == 1">未停用</span>
            <span v-if="inplanList.intaskdise == 2">已停用</span>
          </p>
          <p class="udi_s">实际完成时间：{{ inplanList.intaskactime }}</p>
        </div> -->
      </div>
    </div>

    <div class="flexSC" v-if="inplanuser.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检人员</p>
        </div>
        <div style="display: flex;flex-wrap: wrap;">
          <p class="udi_s" v-for="(item, index) in inplanuser" :key="index">巡检人员：{{ item.username }}</p>
        </div>
      </div>
    </div>

    <div class="flexSC" v-if="inplanpoint.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检点</p>
        </div>
        <div style="display: flex;flex-wrap: wrap;">
          <p class="udiS" v-for="(item, index) in inplanpoint" :key="index"><span>巡检点：{{ item.inpointname }}</span></p>
        </div>
      </div>
    </div>

    <div class="flexSC" v-if="equlist.length != 0">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">巡检设备</p>
        </div>
        <div class="udi_c" v-for="(item, index) in equlist" :key="index">
          <div class="udi_r"><span>{{item.equname}}</span></div>
        </div>
      </div>
    </div>

    <div class="flexSC">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">督办详情</p>
        </div>
        <div class="flexCB">
          <p class="udiIP">督办人：{{ detailInfo.overuser }}</p>
          <p class="udiIP">督办时间：{{ detailInfo.intasktime }}</p>
        </div>
          <p class="udi_p">督办说明：{{ detailInfo.intaskmark }}</p>
      </div>
    </div>
  </el-dialog>

  </div>
</template>

<script>
import { getOversee, getplanDetails } from '@/http/api/onsite'

export default {
  name: 'superRecord',
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      overList: [],
      date: null,
      intaskname: '',
      intaskstate: '',
      taskstateOption: [
        {
          value: '',
          label: '全部状态'
        },
        {
          label: '待巡检',
          value: '1'
        },
        {
          label: '巡检中',
          value: '2'
        },
        {
          label: '已巡检',
          value: '3'
        }
      ],
      detailShow: false,
      detailInfo: {},
      inplanuser: [],
      equlist: [],
      inplanpoint: [],
      inplanList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let stime = ''
      let etime = ''
      if (this.date != null) {
        stime = this.date[0]
        etime = this.date[1]
      }
      const params = {
        intaskname: this.intaskname,
        page_index: this.page,
        page_size: this.size,
        intaskstime: stime,
        intasketime: etime,
        intaskstate: this.intaskstate,
        intaskwttl: this.intaskwttl
      }
      getOversee(params).then(res => {
        if (res.status === '1') {
          this.overList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.overList = []
          this.total = 0
        }
      })
    },
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
      getplanDetails({ inplanid: row.inplanid }).then(res => {
        if (res.status === '1') {
          if (res.data.length != 0) {
            this.inplanuser = res.data[0].inplanuser
            this.equlist = res.data[0].equlist
            this.inplanpoint = res.data[0].inplanpoint
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.intaskname = ''
      this.intaskstate = ''
      this.intaskwttl = ''
      this.date = null
      this.init()
    }
  }
}
</script>

<style scoped>
.over_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.over_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.over_table {
  margin-top: 20px;
}
.ud_left{
  width: 520px;
}
.udi_p,.udi_s,.udiS,.udiIP{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udiIP{
  width: 48%;
}
.udi_s{
  width: 24%;
  margin-right: 1%;
}
.udiS{
  width: 49%;
  margin-right: 1%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_c {
  float: left;
  display: flex;
  width: 160px;
  height: 40px;
  background: #F6F9FF;
  margin-left: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.ud_left{
  width: 700px;
}
</style>
